var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.isBETA
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Deadline verzoeken")
                ])
              ]),
              _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "elementPanel elementPanel--spaced fill-height"
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-end": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.statusOptions,
                                "item-text": "label",
                                "item-value": "value",
                                placeholder: "Status",
                                clearable: "",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.tableFilters.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.tableFilters, "status", $$v)
                                },
                                expression: "tableFilters.status"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            _vm.$store.state.isServiceOrganization
                              ? _c("v-autocomplete", {
                                  staticClass: "with-border",
                                  attrs: {
                                    items: _vm.organizations,
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-details": "",
                                    placeholder: "Bureau",
                                    clearable: "",
                                    multiple: ""
                                  },
                                  model: {
                                    value: _vm.tableFilters.organizations,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tableFilters,
                                        "organizations",
                                        $$v
                                      )
                                    },
                                    expression: "tableFilters.organizations"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.reportTypes,
                                "item-text": "name",
                                "item-value": "uuid",
                                "hide-details": "",
                                placeholder: "Type",
                                clearable: "",
                                multiple: ""
                              },
                              model: {
                                value: _vm.tableFilters.report_types,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tableFilters,
                                    "report_types",
                                    $$v
                                  )
                                },
                                expression: "tableFilters.report_types"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("DataTable", {
                      ref: "deadlineDatatable",
                      attrs: {
                        options: _vm.tableOptions,
                        visibility: _vm.visibility
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "DataTable",
        {
          ref: "deadlineDatatable",
          attrs: { options: _vm.tableOptions, visibility: _vm.visibility }
        },
        [
          _c(
            "template",
            { slot: "options" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "custom-filter", attrs: { shrink: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.statusOptions,
                          "item-text": "label",
                          "item-value": "value",
                          placeholder: "Status",
                          clearable: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.tableFilters.status,
                          callback: function($$v) {
                            _vm.$set(_vm.tableFilters, "status", $$v)
                          },
                          expression: "tableFilters.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "custom-filter", attrs: { shrink: "" } },
                    [
                      _vm.$store.state.isServiceOrganization
                        ? _c("v-autocomplete", {
                            attrs: {
                              items: _vm.organizations,
                              "item-text": "name",
                              "item-value": "id",
                              "hide-details": "",
                              placeholder: "Bureau",
                              clearable: "",
                              multiple: ""
                            },
                            model: {
                              value: _vm.tableFilters.organizations,
                              callback: function($$v) {
                                _vm.$set(_vm.tableFilters, "organizations", $$v)
                              },
                              expression: "tableFilters.organizations"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "custom-filter", attrs: { shrink: "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.reportTypes,
                          "item-text": "name",
                          "item-value": "uuid",
                          "hide-details": "",
                          placeholder: "Type",
                          clearable: "",
                          multiple: ""
                        },
                        model: {
                          value: _vm.tableFilters.report_types,
                          callback: function($$v) {
                            _vm.$set(_vm.tableFilters, "report_types", $$v)
                          },
                          expression: "tableFilters.report_types"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.approvalDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700" },
              model: {
                value: _vm.approvalDialogOpen,
                callback: function($$v) {
                  _vm.approvalDialogOpen = $$v
                },
                expression: "approvalDialogOpen"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "elementPanel__header",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__title",
                              attrs: { sm11: "" }
                            },
                            [
                              _c("h2", [
                                _vm._v(
                                  "Verzoek voor uitstel deadline - " +
                                    _vm._s(
                                      _vm.currentLateRequest.report.case_number
                                    )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__close",
                              attrs: { sm1: "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.closeDialog($event)
                                    }
                                  }
                                },
                                [_vm._v("cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Originele opname datum:")]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.parseDate(
                                    _vm.currentLateRequest.report.planned_at
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Huidige deadline:")]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.parseDate(
                                    _vm.currentLateRequest.report.deadline_at
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Gewenste deadline deskundige:")]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.parseDate(
                                    _vm.currentLateRequest.requested_deadline
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Opgegeven reden:")]),
                            _vm.currentLateRequest.late_reason
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentLateRequest.late_reason.name
                                    )
                                  )
                                ])
                              : _c("p", [_vm._v("-")])
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Opmerking deskundige:")]),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.sanitizeString(
                                    _vm.currentLateRequest.comment
                                  )
                                )
                              }
                            })
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [
                              _vm._v(
                                "Aantal verzoeken voor uitstel op dit rapport:"
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.currentLateRequest.report
                                    .late_requests_count
                                )
                              )
                            ])
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "approvalChoiceContainer",
                                  attrs: { row: "", "hide-details": "" },
                                  model: {
                                    value: _vm.approvalChoice,
                                    callback: function($$v) {
                                      _vm.approvalChoice = $$v
                                    },
                                    expression: "approvalChoice"
                                  }
                                },
                                [
                                  _c(
                                    "v-radio",
                                    {
                                      attrs: {
                                        value: "rejected",
                                        color: "#a09b1b"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: {
                                            slot: "label",
                                            value: "rejected"
                                          },
                                          slot: "label"
                                        },
                                        [_vm._v("Afkeuren")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-radio",
                                    {
                                      attrs: {
                                        value: "approved",
                                        color: "#a09b1b"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: {
                                            slot: "label",
                                            value: "approved"
                                          },
                                          slot: "label"
                                        },
                                        [_vm._v("Goedkeuren")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mt-4": "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  name: "comment",
                                  label: "Opmerkingen"
                                },
                                model: {
                                  value: _vm.comment,
                                  callback: function($$v) {
                                    _vm.comment = $$v
                                  },
                                  expression: "comment"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.approvalChoice === "approved"
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "dialog",
                                      attrs: {
                                        "close-on-content-click": false,
                                        lazy: "",
                                        "nudge-right": "100",
                                        "full-width": "",
                                        "max-width": "290px",
                                        "min-width": "290px"
                                      },
                                      model: {
                                        value: _vm.isEditingDate,
                                        callback: function($$v) {
                                          _vm.isEditingDate = $$v
                                        },
                                        expression: "isEditingDate"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input input-date",
                                        attrs: {
                                          slot: "activator",
                                          placeholder: "DD-MM-JJJJ",
                                          label: "Gewenste nieuwe deadline:",
                                          "error-messages": _vm.dateErrorMessage
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.dateFromTextField($event)
                                          }
                                        },
                                        slot: "activator",
                                        model: {
                                          value: _vm.dateFormatted,
                                          callback: function($$v) {
                                            _vm.dateFormatted = $$v
                                          },
                                          expression: "dateFormatted"
                                        }
                                      }),
                                      _c("v-date-picker", {
                                        ref: "picker",
                                        attrs: {
                                          locale: "nl-nl",
                                          min: "1910-01-01",
                                          color: "#837f16",
                                          "first-day-of-week": "1"
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.isEditingDate = false
                                          }
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "buttonContainer" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-space-between": "", row: "" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "text-light",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.closeDialog($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("general.close")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ml-3 button--link button--link--primary",
                                  attrs: {
                                    href:
                                      "/reports/" +
                                      _vm.currentLateRequest.report.uuid,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(" ga naar dossier ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "MiButton",
                            {
                              staticClass: "float--right",
                              attrs: { color: "success" },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.updateLateRequest($event)
                                }
                              }
                            },
                            [_vm._v(" Bevestigen ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }