import { Component, Vue, Watch } from 'vue-property-decorator';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { ReportType } from '@/models/ReportType';
import { LateRequest } from '@/models/LateRequest';
import { formatDate, isValidDate, setFormattedDatePickerValue, dateErrorMessage, sanitizeString } from '@/support/String';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';

@Component<DeadlineManager>({})
export default class DeadlineManager extends Vue {
  public $pageTitle = 'Deadline manager';

  protected isEditingFrom = false;

  protected isEditingTo = false;

  protected tableFilters: TableFilter = {
    status: 'pending',
  };

  protected approvalDialogOpen = false;

  protected approvalChoice = 'rejected';

  protected comment = '';

  protected isEditingDate = false;

  protected date = '';

  protected deadlineDate = '';

  protected currentLateRequest: LateRequest = new LateRequest();

  protected selectedStatus = 'pending';

  protected statusOptions: StatusOption[] = [{
    label: 'Goedgekeurd',
    value: 'approved',
  }, {
    label: 'Ingediend verzoek',
    value: 'pending',
  }, {
    label: 'Afgewezen',
    value: 'rejected',
  }];

  protected organizations: any[] = [];

  protected reportTypes: ReportType[] = [];

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
  }

  public mounted() {
    this.date = DateTime.local().toFormat('yyyy-MM-dd');
    this.emitBreadcrumb();
    this.getReportTypes();
    this.getOrganizations();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Deadline manager' },
        ],
      });
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((response: Organization[]) => {
        this.organizations = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getReportTypes() {
    new ReportType()
      .all()
      .then((reportTypes: ReportType[]) => {
        this.reportTypes = reportTypes;
      })
      .catch(() => {});
  }

  // DATE
  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected dateChanged() {
    this.dateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingApprovedAtChanged() {
    if (! this.isEditingDate) {
      this.dateFromDatePicker();
    }
  }

  private get dataTableFilters() {
    return this.tableFilters;
  }

  protected updateLateRequest() {
    const payload: any = {
      status: this.approvalChoice,
      status_comment: this.comment ? this.comment : '',
    };

    if (this.approvalChoice === 'approved') {
      payload.approved_deadline = this.date;
    }

    this.currentLateRequest
      .update(payload)
      .then((lateRequest: LateRequest) => {
        this.refreshDataTable();
        this.closeDialog();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  get tableOptions(): Object {
    return {
      model: new LateRequest().filter(this.dataTableFilters),
      name: {
        singular: 'Deadline verzoek',
        plural: 'Deadline verzoeken',
      },
      showTotals: true,
      headers: [
        {
          text: 'Zaaknummer',
          value: 'report',
          action: (lateRequest: LateRequest) => {
            this.openDeadlineDialog(lateRequest);
          },
          sortable: {
            key: 'case_number',
            order: 'ASC',
          },
          transform: (row: any, report: any) => {
            if (! row.case_number) {
              return '';
            }
            if (report.report.is_prio) return `<i aria-hidden="true" class="v-icon material-icons theme--light statusColor--yellow">warning</i> ${row.case_number}`;
            return row.case_number;
          },
        },
        {
          text: 'Type',
          value: 'report',
          transform: (report: any) => (report.type ? report.type.name : ''),
        },
        {
          text: 'Status',
          value: 'status',
          sortable: {
            key: 'status',
            order: 'ASC',
          },
          transform: (status: string) => {
            if (status == 'approved') {
              return '<span class="statusPill statusColor--green">Goedgekeurd</span>';
            } if (status == 'rejected') {
              return '<span class="statusPill statusColor--red">Afgewezen</span>';
            }
            return '<span class="statusPill statusColor--orange">Ingediend verzoek</span>';
          },
        },
        {
          text: 'Bureau',
          value: 'organization',
          sortable: {
            key: 'organization',
            order: 'ASC',
          },
          transform: (organization: any) => (organization && organization.name ? organization.name : ''),
        },
        {
          text: 'Expert',
          value: 'organization_user',
          transform: (organization_user: any) => (organization_user && organization_user.name ? organization_user.name : ''),
        },
        {
          text: 'Originele deadline',
          value: 'original_deadline',
          sortable: {
            key: 'original_deadline',
            order: 'ASC',
          },
          transform: (original_deadline: any) => formatDate(original_deadline, 'dd-LL-yyyy'),
        },
        {
          text: 'Verzoek nieuwe deadline',
          value: 'requested_deadline',
          sortable: {
            key: 'requested_deadline',
            order: 'ASC',
          },
          transform: (requested_deadline: any) => formatDate(requested_deadline, 'dd-LL-yyyy'),
        },
        {
          text: 'Opmerking deskundige',
          value: 'comment',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: (datatable: DataTable, lateRequest: LateRequest) => {
            this.openDeadlineDialog(lateRequest);
          },
        },
      ],
    };
  }

  protected openDeadlineDialog(lateRequest: LateRequest) {
    this.comment = '';
    this.approvalChoice = lateRequest.status === 'approved' ? 'approved' : 'rejected';
    this.approvalDialogOpen = true;
    this.currentLateRequest = lateRequest;
    if (lateRequest.requested_deadline) {
      this.date = DateTime.fromString(lateRequest.requested_deadline, 'yyyy-MM-dd hh:mm:ss').toFormat('yyyy-MM-dd');
    }
  }

  protected parseDate(date: string) {
    if (! date) {
      return '';
    }
    if (date.length < 12) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy');
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss').toFormat('dd-MM-yyyy');
  }

  protected closeDialog() {
    this.approvalDialogOpen = false;
    this.deadlineDate = '';
    this.approvalChoice = 'reject';
    this.comment = '';
  }

  protected refreshDataTable() {
    document.dispatchEvent(
      new CustomEvent('datatable:hook', {
        detail: (dataTable: DataTable) => {
          dataTable.refresh();
        },
      }),
    );
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface StatusOption {
  label?: string;
  value?: string;
}

interface TableFilter {
  status?: string;
  expert?: string;
  organization?: string;
  type?: string;
}
